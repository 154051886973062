.main {
  width: 100%;
  height: 100%;
  background-color: #f5f1ef;
}

span,
a {
  font-family: Raleway;
  color: #fbfbfb;
}

.main-header {
  background-color: #514134;
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
}

.main-header-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.main-header-icons {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 50px;
}

.main-header-icons img {
  height: 70px;
}

.main-footer {
  background-color: #554d42;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.main-footer-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 250px;
}

.main-footer-info-bar {
  background-color: #554d42;
  height: 80px;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  cursor: pointer;
}

.main-footer-info-bar span {
  font-size: 40px;
  font-weight: 100;
}

.main-footer-info-bar span b {
  font-weight: 500;
}

.span-title {
  font-size: 60px;
  font-weight: 100;
}

.span-title b {
  font-weight: 500;
}

.span-contact {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.span-email {
  font-size: 14px;
  font-weight: 200;
  letter-spacing: 2px;
}

.main-footer-developer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #3a322f;
  height: 60px;
}

.main-footer-developer img {
  margin-right: 10px;
}

.main-footer-developer a {
  font-size: 10px;
  letter-spacing: 1.5px;
  font-weight: 300;
  color: #fbfbfb;
  text-decoration: none;
}

.search-container {
  width: 100%;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  display: flex;
}

.button-container {
  margin-right: 20px;
}

.main-contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 45px;
}

.main-footer-icons {
  width: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.content-cards {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80%;
}

section {
  background-color: #f5f1ef;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}

.section-left {
  align-items: flex-start;
}

.section-right {
  align-items: flex-end;
  background-color: #e7e3e0;
}

.section-title {
  background-color: #a07258;
  display: flex;
  align-items: center;
  padding: 10px 0px;
  width: auto;
  min-width: 500px;
  /* max-width: 580px; */
}

.section-title span {
  font-size: 34px;
  margin: 0 20px;
  font-weight: 100;
  line-height: 40px;
}

.section-left .section-title {
  justify-content: end;
}

.section-right .section-title {
  justify-content: start;
}

.section-contain {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: stretch;
  justify-content: center;
  padding: 50px 0;
  gap: 20px;
}

.section-top-tags-contain {
  padding: 50px 20%;
  display: flex;
  flex-wrap: wrap;
}

.tag-container {
  height: 30px;
  background-color: #e2d9d4;
  border: 1px solid #7e756a;
  border-radius: 20px;
  flex: 0 auto;
  align-items: center;
  /*     margin-bottom: 10px;
    margin-left: 0;
    margin-right: 10px; */
  margin: 5px;
  padding: 10px;
  display: flex;
  cursor: pointer;
  will-change: transform;
  transition: transform 450ms;
}

.tag-container:hover {
  transition: transform 125ms;
  transform: translateY(-3px);
}

.tag-container span {
  color: #2f2f2f;
  font-family: Raleway;
  font-size: 16px;
}

.reset-filter-button {
  height: 24px;
  width: 24px;
  background-color: #7e756a;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  transition: all 0.3s;
  cursor: pointer;
  margin-right: 5px;
}

.reset-filter-button:hover {
  background-color: #a07258;
}

.reset-filter-button img {
  width: 13px;
  height: 13px;
}

.search-results-message {
  background-color: transparent !important;
  height: 25px;
  margin: 0px;
}

.search-results-message span {
  color: #2f2f2f;
  font-family: Raleway;
  font-size: 13px;
  font-weight: 500;
}

.search-results-message img {
  height: 13px;
  width: 13px;
  margin-right: 5px;
}

.main-text-section {
  width: 100%;
  padding: 30px 10%;
  text-align: center;
}

.main-text-section span {
  font-size: 18px;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.view-mode-container {
  width: 1220px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 10px;
}

.view-mode-container span {
  width: 135px;
  color: #2f2f2f !important;
  cursor: pointer;
  transition: 0.3s all;
}

.view-mode-container span:hover {
  font-weight: 600;
}

.selected-mode {
  font-weight: 600;
}

.resource-image-gallery-container {
  width: 1220px;
}

.resource-image-gallery-container .image-gallery-slide-wrapper {
  margin-bottom: 10px;
}

.resource-image-gallery-container .image-gallery-thumbnails-container {
  transform: translate3d(0px, 0px, 0px);
  transition: all 450ms ease-out 0s;
  width: 1220px;
  height: auto;
  display: grid;
  grid-template-columns: repeat(10, auto);
  align-items: center;
  justify-content: center;
}

.resource-image-gallery-container .image-gallery-thumbnails-container button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.resource-image-gallery-container
  .image-gallery-thumbnails-container
  button
  .image-gallery-thumbnail-inner {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.resource-image-gallery-container
  .image-gallery-thumbnails-container
  button:hover,
.resource-image-gallery-container
  .image-gallery-thumbnails-container
  button:focus,
.resource-image-gallery-container
  .image-gallery-thumbnails-container
  button.active {
  border: 4px solid #7e756a !important;
}

.resource-image-gallery-container
  .image-gallery-thumbnails-container
  button
  .image-gallery-thumbnail-inner
  .image-gallery-thumbnail-image {
  max-height: 100% !important;
  max-width: 100% !important;
  object-fit: contain !important;
}

.resource-image-gallery-container
  .image-gallery-thumbnail
  + .image-gallery-thumbnail {
  margin-left: 0px !important;
}

.resource-image-gallery-container
  .image-gallery-thumbnails-container
  .image-gallery-thumbnail {
  width: 120px !important;
  height: 120px !important;
}

.resource-image-gallery-container .image-gallery-image {
  max-height: 300px !important;
}

.selected-gallery-resource {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2f2f2f;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  gap: 5px;
  padding: 10x 0;
}

.selected-gallery-resource img {
  height: 13px;
  width: 13px;
  cursor: pointer;
}

.resource-image-gallery-container .image-gallery-slide .image-gallery-image {
  width: auto !important;
}

.resource-image-gallery-container
  .image-gallery-fullscreen
  .image-gallery-image {
  max-height: 100% !important;
}

.main-header-title .option-button-container {
  gap: 20px;
}

.filter-section-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

.filter-section-container select {
  color: #554d42 !important;
  background-color: #e2d9d4 !important;
}

.filter-section-container span {
  font-size: 14px;
  color: #2f2f2f;
}

.section-contain-map {
  display: flex;
}

/* TABLET VERSION ******************************************************************************************/
@media screen and (max-width: 991px) {
  .view-mode-container {
    width: 100% !important;
  }
  .resource-image-gallery-container .image-gallery-thumbnails-container {
    width: 100% !important;
    grid-template-columns: repeat(6, auto) !important;
  }
}

/* MOBILE VERSION *******************************************************************************************/

@media screen and (max-width: 479px) {
  .resource-image-gallery-container {
    width: 100% !important;
    padding: 0 10px;
  }
  .view-mode-container {
    width: 100% !important;
  }
  .resource-image-gallery-container .image-gallery-thumbnails-container {
    width: 100% !important;
    grid-template-columns: repeat(3, auto) !important;
  }
}
