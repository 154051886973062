.map-container {
  height: 700px;
  width: 80%;
  margin: 0 auto;
}

.leaflet-control-zoom-in span,
.leaflet-control-zoom-out span {
  color: #554d42 !important;
}

.marker-resource-popup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-right: 15px;
}

.marker-resource-popup-info {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.marker-resource-popup span:last-child {
  font-size: 12px !important;
  font-weight: 300 !important;
}

.marker-resource-popup-info span,
.leaflet-popup-close-button span {
  color: #554d42;
}

.leaflet-popup-close-button span {
  font-size: 30px !important;
}

.leaflet-popup-content {
  width: auto !important;
  min-width: 300px !important;
  padding: 10px !important;
  margin: 0px !important;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.marker-resource-popup img {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}

.leaflet-popup-close-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.marker-button-small {
  width: 70px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 2px 0 4px;
  margin: 0px;
}

.marker-button-small .button-small-text {
  font-size: 10px;
}

/* TABLET VERSION ******************************************************************************************/
@media screen and (max-width: 991px) {
  .map-container {
    width: 100%;
    height: 500px;
  }
}
