.button-login-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 10px;
}

.error-login-container {
  width: 100%;
  background-color: rgba(237, 51, 21, 0.2);
  margin-top: 5px;
  padding: 3px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.error-login-container img {
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.error-login-container span {
  color: #cf0808;
  font-weight: 600;
  font-size: 13px;
  font-family: Raleway;
}

.login-header-icons {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 20px 50px 0 50px;
}

.login-header-icons img {
  height: 70px;
}

.header-title {
  position: relative;
  z-index: 20;
}
