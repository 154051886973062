.navigation-bar-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 20px;
}

.navigation-bar-container img.barIcon {
  height: 20px;
  width: 20px;
  cursor: pointer;
  will-change: transform;
  transition: transform 450ms;
}

.navigation-bar-icons {
  display: flex;
  flex-direction: row;
}

.navigation-bar-icons img {
  height: 55px;
  cursor: default !important;
}

.navigation-bar-container img.barIcon:hover {
  transition: transform 125ms;
  transform: translateY(-3px);
}

.navigation-bar-container .user-connected {
  margin-bottom: 10px !important;
  cursor: default;
}

.navigation-bar-container .icons-username-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
