.ql-editor {
  background-color: #ffffff !important;
  color: #2f2f2f;
  padding: 0 10px;
  font-family: Raleway, sans-serif;
  font-size: 14px;
  font-weight: 400;
  min-height: 150px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  resize: vertical !important;
}

.ql-picker-label,
.ql-picker-options span {
  color: #2f2f2f;
}

.ql-tooltip {
  font-family: Raleway, sans-serif !important;
}

.ql-snow a {
  color: #7e756a !important;
}

.ql-tooltip a.ql-action {
  color: #929292 !important;
}

.ql-formats button svg path.ql-fill,
.ql-formats button svg rect.ql-fill,
.ql-formats button svg path.ql-stroke,
.ql-formats button svg line.ql-stroke {
  transition: 0.3s ease-in-out;
}

.ql-formats button svg path.ql-fill {
  fill: #929292 !important;
}

.ql-formats button:hover svg path.ql-fill {
  fill: #2f2f2f !important;
}

.ql-formats button svg rect.ql-fill {
  fill: #929292 !important;
}

.ql-formats button:hover svg rect.ql-fill {
  fill: #2f2f2f !important;
}

.ql-formats button svg path.ql-stroke {
  stroke: #929292 !important;
}

.ql-formats button:hover svg path.ql-stroke {
  stroke: #2f2f2f !important;
}

.ql-formats button svg line.ql-stroke {
  stroke: #929292 !important;
}

.ql-formats button:hover svg line.ql-stroke {
  stroke: #2f2f2f !important;
}
