.resource-section-content .left-arrow,
.resource-section-content .right-arrow {
  display: none !important;
}

.resource-metadata-container-header,
.resource-section-title {
  cursor: pointer;
}

.checkbox-label {
  width: 20px !important;
}

.resource-medias-container {
  width: 300px;
  height: 300px;
  margin-right: 20px;
}

.only-one button {
  display: none !important;
}

.no-tags-info {
  color: #2f2f2f;
  font-family: Raleway, sans-serif;
  font-size: 13px;
  font-weight: 500;
}

.form-block-6 {
  position: relative;
}

.element-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.element-container span {
  color: #2f2f2f;
  margin-right: 5px;
}

.lightbox-image-option-container > input {
  display: none !important;
}

.lightbox-image-container img {
  object-fit: cover !important;
}

.suggestion-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0px 10px;
  margin-bottom: 10px;
}

.suggestion-container img {
  height: 12px;
  width: 12px;
  margin-right: 5px;
  margin-top: 3px;
}

.suggestion-container-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  margin-left: 5px;
}

.suggestion-container-span {
  font-size: 12px !important;
}

.suggestion-span,
.no-suggestion-span {
  cursor: pointer;
  color: #2f2f2f;
  padding-top: 0;
  font-size: 12px;
  font-weight: 500;
  margin-right: 5px;
  transition: font-weight 250ms;
  width: fit-content;
}

.no-suggestion-span {
  cursor: default !important;
}

.suggestion-span:hover {
  font-weight: 700;
}

.image-slide-container {
  cursor: pointer;
}

.coordsMsg,
.coordsMsg a {
  color: #929292;
  font-family: Raleway, sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.coordsMsg a {
  color: #7a7a7a;
  font-weight: 500;
}

.tag-container-extra {
  background-color: #e2d9d496 !important;
  height: 20px !important;
  margin-right: 5px !important;
  padding: 10px 8px !important;
  font-size: 13px !important;
}

.saving-information {
  width: 80%;
  height: 30px;
  background-color: #b6deb9;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  animation: fade 3s;
}

.related-resources-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 5px 0 14px 0;
  justify-content: flex-start;
}

.related-resources-container .card {
  max-width: 270px !important;
}

@media screen and (min-width: 1920px) {
  .saving-information {
    width: 60% !important;
  }
}

.saving-information span {
  color: #2f2f2f;
  font-size: 13px;
  font-weight: 500;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.input-uc {
  text-transform: uppercase;
}

/* .gallery-buttons-custom button,
.gallery-buttons-custom .image-gallery-bullets {
    z-index: 0!important;
} */

/* UPLOADER MEDIA SPINNER ***************************************************************************************/

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  background-color: #e5e0dd;
}

.loader {
  font-size: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #7e756a,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #7e756a, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #7e756a,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #7e756a,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #7e756a,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #7e756a,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #7e756a,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #7e756a;
  }
}
