.data-info-container img {
  width: 30px;
}

.badge-extra {
  margin-right: 0px !important;
  border: none !important;
  margin-bottom: 0px !important;
  align-items: flex-end !important;
}

.user-profile-button-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 10px 0;
}

.data-info-container-edit {
  border: none !important;
  margin-bottom: 5px !important;
}

.user-profile-box .error-container {
  margin-top: 0px !important;
}

.change-password-container {
  border: 1px solid #c0bbb4;
  width: 100%;
  margin-bottom: 20px;
  background-color: #eee9e7;
  padding: 10px;
}

.buttons-container-row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.user-profile-box .saving-information {
  width: 100% !important;
}
