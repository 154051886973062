.ban-op {
  background-color: #bbb3b0 !important;
}

.ban-op:hover {
  background-color: #c0bbb49c !important;
}

.button-disabled {
  opacity: 0.5;
}

.button-disabled:hover {
  background-color: transparent;
}
