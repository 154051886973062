.user-search-container{
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #7e756a;
    border-radius: 20px;
    padding-right: 4px;
}

.user-search-input{
    background-color: #7e756a;
    border-radius: 20px;
    height: 20px;
    font-family: Raleway, sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #fbfbfb;
    width: 160px;
}

.user-search-button{
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #7e756a;
    cursor: pointer;
}

.user-search-button img{
    width: 12px;
    height: 12px;
}

.users-search-bar{
    justify-content: flex-start!important;
}

.admin-panel-last-resources-provided-container .resource-provided-contain-info:last-child{
    margin-bottom: 0px!important;
}

.op-row .user-field-value{
    display: flex;
    align-items: center;
}