.select-multiple-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  cursor: default;
  background-color: #fbfbfb;
  margin-bottom: 5px;
  padding: 0 10px;
  height: auto;
  min-height: 30px;
  border-radius: 0px;
  border: 1px solid #cccccc;
  padding-right: 20px;
}

.select-multiple-container span {
  font-family: Raleway, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #2f2f2f;
}

.select-multiple-container img.toggle-select {
  width: 12px;
  height: 12px;
  position: absolute;
  right: 5px;
}

.select-multiple-options {
  color: #2f2f2f;
  letter-spacing: 0.3px;
  background-color: #fbfbfb;
  border: 1px solid #cccccc;
  margin-bottom: 0;
  font-family: Raleway, sans-serif;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  position: absolute;
  z-index: 1;
}

.select-multiple-options .op-container {
  cursor: default;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 5px;
  gap: 10px;
}

.select-multiple-options .op-container span {
  font-family: Raleway, sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #2f2f2f;
}

.select-multiple-options .op-container:hover {
  background-color: #cccccc;
}

.op-container .option-checked {
  height: 10px;
  width: 10px;
  border: 1px solid #7e756a;
  display: flex;
  align-items: center;
  justify-content: center;
}

.op-container .option-checked img {
  width: 100%;
  height: 100%;
}

.select-multiple-container .op-selected-container {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-right: 12px;
}

.select-multiple-container .op-selected-container img {
  width: 12px;
  height: 12px;
  cursor: pointer;
}
