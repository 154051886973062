.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #e3e3e3bf;
}

.modal-box {
    background-color: #dfdbd5 !important;
    justify-content: center;
    width: 700px !important;
    height: 150px;
    border: 1px solid #c1bcb5;
    border-radius: 20px;
    box-shadow:rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
        rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

/* MOBILE **********************************************************************************************/

@media screen and (max-width: 479px){
    .modal-box{
        width: 90%!important;
    }
}