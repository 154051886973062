.button{
    height: 50px;
    width: 145px;
    background-color: #a49485;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .3s;
    text-align: center;
}

.button span{
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 3px;
}

.button:hover{
    background-color:#A07258;
}