.tag-card-container {
  width: 100%;
  background-color: #eee9e7;
  border: 1px solid #c0bbb4;
  flex-direction: column;
  padding: 10px;
  display: flex;
}

.tags-container-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2px;
  position: relative;
}

.tags-container-list span {
  margin-right: 0px !important;
}

.tag-card-container div {
  margin-right: 0px;
}

.field-label-extra {
  display: flex;
  gap: 2px;
  flex-direction: row;
  align-items: center;
}

.op-extra {
  gap: 5px;
}

.op-extra-scroll {
  max-height: 310px;
  overflow-y: scroll;
}
