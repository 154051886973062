.register-user-content .data-field-container {
  gap: 20px;
}

.register-user-content .data-info-container {
  border-bottom: none !important;
  margin-right: 0px !important;
}

.error-container {
  margin-bottom: 10px;
}

.input-error {
  border: 1px solid #cf0808 !important;
}

@media screen and (max-width: 479px) {
  .register-user-content .data-field-container {
    gap: 0px !important;
  }
}
