.op-row{
    flex-direction: row;
}

.extra-center{
    align-items: center;
}

.op-column{
    flex-direction: column;
    align-items: flex-start!important;
}

.user-options-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.user-option-button{
    width: 100px;
}

.user-option-button .option-button{
    margin-left: 0px!important;
}

.user-buttons-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 70px;
}

/* The switch - the box around the slider */
.switch-role {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    margin-bottom: 0px!important;
}

/* Hide default HTML checkbox */
.switch-role input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider-role {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #7e756a;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider-role:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    left: -4px;
}

.switch-role :checked+.slider-role {
    background-color: #bbb3b0;
}

.switch-role :focus+.slider-role {
    box-shadow: 0 0 1px #bbb3b0;
}

.switch-role :checked+.slider-role:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider-role.round {
    border-radius: 34px;
}

.slider-role.round:before {
    border-radius: 50%;
}