.social-button{
    height: 30px;
    width: 30px;
    background-color: #3A322F;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    transition: all .3s;
}

.social-button img{
    width: 16px;
    height: 16px;
}

.social-button:hover{
    background-color: #A07258;
}