.search {
  height: 50px;
  width: 845px;
  display: flex;
  flex-direction: row;
}

.search-div {
  width: 700px;
  height: 50px;
  background-color: #f5f1ef;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 0 1px #a49485;
  flex-direction: row;
  justify-content: end;
}

input {
  width: 100%;
  outline: none;
  border: none;
  padding-left: 15px;
  background-color: transparent;
  font-size: 18px;
  font-family: Raleway;
}

.search-div .reset-button img {
  height: 10px;
  width: 10px;
}

.search-div .reset-button {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background-color: #a49485;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 20px;
  transition: all 0.3s;
  position: absolute;
}

.search-div .reset-button:hover {
  background-color: #a07258;
}

.search-container {
  display: flex;
  flex-direction: column;
  z-index: 0;
  margin-bottom: 50px;
}

.search-div-result {
  width: 698px;
  max-height: 225px;
  background-color: #f5f1ef;
  position: absolute;
  z-index: 10;
  margin-top: -1px;
  display: flex;
  flex-direction: column;
  -webkit-box-shadow: 10px 10px 20px -17px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 20px -17px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 20px -17px rgba(0, 0, 0, 0.75);
  border: 1px solid #a49485;
}

.search-div-result .element-found-container {
  cursor: pointer;
  height: auto;
  padding: 10px;
  display: flex;
  align-items: center;
}

.search-div-result .element-found-container span {
  color: #2f2f2f !important;
}

.search-div-result .element-found-container:hover {
  background-color: #c7b69f;
}

.search-div-result .element-found-container:hover span {
  font-weight: 600;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a49485;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a49485;
}

.hide-scrollbar {
  overflow-y: hidden;
}
