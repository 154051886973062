.message-box-container {
    display: flex;
    flex-direction: column;
    background-color: #a5998b78;
    align-items: center;
    padding: 20px;
    margin-bottom: 10px;
    width: 80%;
}

.message-box-container span {
    color: #2f2f2f;
    font-weight: 400;
    font-size: 15px;
    font-family: Raleway;
}

.message-box-buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
    width: 250px;
}

.message-box-question-container{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.message-box-question-container img{
    height: 15px;
    width: 15px;
    margin-right: 5px;
    margin-top: 2px;
}

/* CSS */
.button-18 {
    background-color: #7e756a;
    color: #fbfbfb;
    cursor: pointer;
    font-family: Raleway;
    font-size: 12px;
    height: 30px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
    transition: all .3s;
    font-weight: 400;
    letter-spacing: 3px;
}

.button-18:hover {
    background-color: #554d42;
    color: #fbfbfb;
}