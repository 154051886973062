.card {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  cursor: default;
  justify-content: space-between;
  transition: box-shadow ease 0.5s;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.card:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.card span {
  font-family: Raleway;
  font-weight: 500 !important;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 400 !important;
}

.card-img {
  height: 220px;
  width: 100%;
  padding: 10px;
}

.card-info {
  width: 100%;
  background-color: #fbfbfb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  flex-grow: 1;
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-info .card-info-title {
  font-size: 14px !important;
  display: inline;
}

.card-info .card-info-author {
  font-weight: 600 !important;
  font-size: 16px !important;
  margin: 5px 0px 5px 0px;
}

.card-info .card-info-user {
  font-size: 12px !important;
  font-weight: 200 !important;
  padding-top: 5px;
  border-top: 1px solid #fbfbfb40;
}

.card-info span {
  color: #554d42 !important;
}

.button-open-resource-extra {
  margin-bottom: 10px;
}

.license-icon {
  height: 13px !important;
  width: 13px !important;
  position: relative;
  margin-left: 5px;
  bottom: 2px;
}
